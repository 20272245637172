<template>
  <div class="addInstallationEvent">
    <LoadingPlaceholder v-show="blockButton" />
    <form v-show="!blockButton">
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Installation Event
        </h4>
        <hr class="m-0 mb-3" />
        <label>Name</label>
        <input
          v-model="installationEvent.name"
          v-focus
          :class="['form-control']"
          type="text"
        />
        <hr class="m-0 mb-3" />
        <label>{{ $t("eventType") }}</label>
        <LoadingPlaceholder v-if="loading" />
        <ComboBox
          v-else
          v-model="installationEvent.eventType"
          :data-source="eventTypes"
          :placeholder="'Select a event type...'"
          class="form-control"
        />
        <div class="m-0 mb-3 mt-3" />
        <div class="form-check form-check-inline mb-3 mr-5">
          <input
            id="executeAction"
            v-model="installationEvent.executeAction"
            class="form-check-input"
            type="checkbox"
          />
          <label
            class="form-check-label"
            for="executeAction"
          >Automatically disable Monitoring during Event</label>
        </div>
        <div
          v-if="hasMaintenanceSetting"
          class="form-check form-check-inline mb-3 mr-5"
        >
          <input
            id="switchToMaintenanceMode"
            v-model="installationEvent.switchToMaintenanceMode"
            class="form-check-input"
            type="checkbox"
          />
          <label
            class="form-check-label"
            for="switchToMaintenanceMode"
          >Automatically activate maintenance mode / page during event</label>
        </div>
        <label>{{ $t("startDate") }}</label>
        <date-picker
          v-model="installationEvent.startDate"
          :editable="true"
          :clearable="false"
          type="datetime"
          lang="en"
          format="DD.MM.YYYY HH:mm"
          value-type="date"
          :first-day-of-week="1"
          class="ml-3"
        />
        <div class="m-0 mb-3" />
        <label>{{ $t("endDate") }}</label>
        <date-picker
          v-model="installationEvent.endDate"
          :editable="true"
          :clearable="false"
          type="datetime"
          lang="en"
          format="DD.MM.YYYY HH:mm"
          value-type="date"
          :first-day-of-week="1"
          class="ml-4"
        />
        <div class="m-0 mb-3" />
        <label>{{ $t("description") }}</label>
        <textarea
          v-model="installationEvent.description"
          class="form-control m-input m-input--air"
          name="Description"
          rows="5"
          placeholder="Please enter your description"
        />
        <div class="m-0 mb-3" />
        <template v-if="authenticationHasRole('scope_staff')">
          <label
            for="keycloakUsers"
            class="form-label"
          >User to notify (will be notified 5 min before start)</label>
          <LoadingPlaceholder v-if="userLoading" />
          <Multiselect
            v-else-if="keycloakUsers"
            id="keycloakUsers"
            v-model="selectedKeycloakUsers"
            tag-placeholder="Not found"
            placeholder="Select User"
            :options="keycloakUsers"
            :custom-label="customLabel"
            :multiple="true"
            :taggable="true"
            track-by="email"
            :hide-selected="true"
            :searchable="true"
            :close-on-select="true"
            class="mb-4"
          />
          <label
            for="notifyDescription"
            class="form-label"
          >Notify description</label>
          <textarea
            v-model="installationEvent.notifyDescription"
            class="form-control m-input m-input--air"
            name="notifyDescription"
            rows="5"
            placeholder="If this field is left empty, the Event description, will be sent."
          />
          <div class="m-0 mb-3" />
          <p>
            <b>If you select Installations below, the Event will be created on
              this Installations too</b>
          </p>
          <LoadingPlaceholder v-if="installationLoading" />
          <div
            v-else
            class="d-flex"
            style="justify-content: space-between"
          >
            <p class="mt-2">
              Installations added: {{ selectedInstallations.length }}
            </p>
            <button
              class="btn btn-primary float-right"
              @click.prevent="openModal()"
            >
              {{ $t("add") }} Installations
            </button>
          </div>
          <div class="clearfix" />
        </template>
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="
          !installationEvent.eventType ||
            !installationEvent.startDate ||
            !installationEvent.endDate ||
            blockButton
        "
        @click.prevent="addInstallationEvent()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t("add") }}
      </button>
      <div class="clearfix" />
    </form>
    <SweetModal
      ref="installationModal"
      title="Installations"
      blocking
      class="overflowHidden"
      @close="openSelectionModal = false"
    >
      <InstallationSelectList
        v-if="openSelectionModal"
        :installations="installations"
        :selected="selectedInstallations"
        @setSelectedList="tempSelectedInstallations = $event"
      />
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="
          $refs.installationModal.close();
          openSelectionModal = false;
        "
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t("cancel") }}
      </button>
      <button
        slot="button"
        class="btn btn-success float-right mb-3"
        @click="confirm"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />Confirm
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
import DatePicker from "vue2-datepicker";
import { ComboBox } from "@progress/kendo-dropdowns-vue-wrapper";
import { authenticationMixin } from "@/mixins/authenticationMixin";
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import InstallationSelectList from "../Installation/InstallationSelectList.vue";

export default {
  name: "AddInstallationEvent",
  components: {
    ComboBox,
    DatePicker,
    InstallationSelectList,
    Multiselect: () => import("vue-multiselect"),
    SweetModal,
  },
  mixins: [kendoGridMixin, authenticationMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    hasMaintenanceSetting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      installationEvent: {
        notifyDescription: "",
      },
      loading: false,
      eventTypes: [],
      userLoading: false,
      keycloakUsers: null,
      selectedKeycloakUsers: [],
      blockButton: false,
      selectedInstallations: [],
      tempSelectedInstallations: [],
      installations: [],
      selectedField: "selected",
      installationLoading: false,
      openSelectionModal: false,
    };
  },
  created() {
    this.getEventTypes();
    this.getUsers();
    if (this.authenticationHasRole("scope_staff")) {
      this.getInstallations();
    }
  },
  methods: {
    openModal() {
      this.$refs.installationModal.open();
      this.openSelectionModal = true;
      if (this.installations) {
        this.installations.forEach((installation) => {
          if (
            this.selectedInstallations.some((x) => x.id === installation.id)
          ) {
            installation.selected = true;
          }
        });

        this.tempSelectedInstallations = this.kgm_computedAllGridItems(
          this.installations
        ).filter((x) => {
          return x.selected;
        });
      }
    },
    confirm() {
      this.selectedInstallations = [...this.tempSelectedInstallations];
      this.$refs.installationModal.close();
      this.openSelectionModal = false;
    },
    customLabel({ name, email }) {
      if (name) {
        return name;
      }
      if (email) {
        return email;
      }
      return "";
    },
    getUsers() {
      this.userLoading = true;
      this.axios
        .get("/User/GetKeycloakUsers")
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.keycloakUsers = response.data;
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    getInstallations() {
      this.installationLoading = true;
      this.axios
        .get("/Installation/GetAll")
        .then((response) => {
          this.installations = response.data;
        })
        .finally(() => {
          this.installationLoading = false;
        });
    },
    async getEventTypes() {
      this.loading = true;
      await this.axios
        .get("/Installation/GetInstallationEventTypes")
        .then((response) => {
          if (response && response.status && response.status == 200) {
            this.eventTypes = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async addInstallationEvent() {
      this.blockButton = true;
      if (!this.installationEvent.notifyDescription) {
        this.installationEvent.notifyDescription =
          this.installationEvent.description;
      }
      let postObject = {
        name: this.installationEvent.name,
        eventType: this.installationEvent.eventType,
        startDate: this.installationEvent.startDate,
        endDate: this.installationEvent.endDate,
        installationId: this.installationId,
        description: this.installationEvent.description,
        executeAction: this.installationEvent.executeAction,
        switchToMaintenanceMode: this.installationEvent.switchToMaintenanceMode,
        usersToNotify: this.selectedKeycloakUsers.map((x) => x.email).join(";"),
        notifyDescription: this.installationEvent.notifyDescription,
        installations: this.selectedInstallations.map((x) => ({
          installationId: x.id,
          name: x.name,
        })),
      };

      await this.axios
        .post("/Installation/AddInstallationEvent", postObject)
        .then((res) => {
          if (res) {
            this.blockButton = false
            this.$snotify.success(
              this.$t("installationEvent.addedSuccessfully")
            );
            this.$emit("reloadEvents");
          }
        })
        .catch((error) => {
          this.$snotify.error(error.message);
        });
      this.blockButton = false;
    },
  },
};
</script>

<style scoped>
.addInstallationEvent .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
