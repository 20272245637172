<template>
  <div class="eventList">
    <Grid
      :ref="kgm_ref"
      :style="{ height: 'auto' }"
      :data-items="kgm_computedGridItems(eventsCopy)"
      :columns="kgm_responsiveColumns()"
      :filterable="true"
      :filter="kgm_filter"
      :pageable="kgm_pagable"
      :page-size="kgm_take"
      :skip="kgm_skip"
      :take="kgm_take"
      :total="kgm_allGridItems(eventsCopy)"
      :sortable="{
        allowUnsort: kgm_allowUnsort,
        mode: kgm_sortMode,
      }"
      :sort="kgm_sort"
      selected-field="selected"
      @rowclick="kgm_gridOnRowClick, onSelectionChange"
      @filterchange="kgm_gridFilterChange"
      @pagechange="kgm_gridPageChange"
      @sortchange="kgm_gridSortChange"
      @selectionchange="onSelectionChange"
      @headerselectionchange="onHeaderSelectionChange"
    >
      <div
        slot="textFilter"
        slot-scope="{ props, methods }"
        class="input-group"
      >
        <input
          ref="textFilterInput"
          :value="props.value"
          type="text"
          class="form-control mr-2 rounded-right"
          placeholder="please enter an value"
          @input="
            (event) => {
              methods.change({
                operator: 'contains',
                field: props.field,
                value: event.target.value,
                syntheticEvent: event,
              });
            }
          "
        />
        <div
          v-if="props.value"
          class="input-group-append"
        >
          <button
            class="btn btn-light border rounded"
            @click="
              kgm_resetInput('textFilterInput', (event) => {
                methods.change({
                  operator: '',
                  field: '',
                  value: '',
                  syntheticEvent: event,
                });
              })
            "
          >
            <font-awesome-icon
              class="gray"
              icon="times"
            />
          </button>
        </div>
      </div>
    </Grid>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";

export default {
  name: "InstallationEventSelectList",
  mixins: [kendoGridMixin],
  props: {
    events: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    selected: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectedField: "selected",
      selectedEvents: [],
      eventsCopy: [],
      kgm_columns: [
        {
          field: "selected",
          width: "50px",
          filterable: false,
        },
        {
          field: "name",
          filterable: true,
          filter: "text",
          title: "Name",
          filterCell: "textFilter",
        },
        {
          field: "installationId",
          filterable: true,
          filter: "text",
          title: "Installation Id",
          width: "200px",
          filterCell: "textFilter",
        },
        {
          field: "installationName",
          filterable: true,
          filter: "text",
          title: "Installation Name",
          filterCell: "textFilter",
        },
      ],
    };
  },
  created() {
    if (this.events && this.events.length) {
      this.eventsCopy = [...this.events];
      this.eventsCopy = this.eventsCopy.map((x) => ({
        ...x,
        selected: this.selected.some(ev => ev.id === x.id)
      }));
    }
  },
  methods: {
    onSelectionChange(event) {
      this.$set(
        event.dataItem,
        this.selectedField,
        !event.dataItem[this.selectedField]
      );
      this.selectedEvents = this.kgm_computedAllGridItems(
        this.eventsCopy
      ).filter((x) => {
        return x.selected;
      });
      this.$emit("setSelectedList", this.selectedEvents);
    },
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      this.eventsCopy = this.eventsCopy.map((x) => ({
        ...x,
        selected: checked,
      }));
      this.selectedEvents = this.kgm_computedAllGridItems(
        this.eventsCopy
      ).filter((x) => {
        return x.selected;
      });
      this.$emit("setSelectedList", this.selectedEvents);
    },
  },
};
</script>
<style>
.eventList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
