var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eventList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({ height: 'auto' }),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.installationEvents),"columns":_vm.kgm_responsiveColumns(),"filterable":false,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.installationEvents),"sortable":{
        allowUnsort: true,
        mode: _vm.kgm_sortMode,
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"dateTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem)))+" ")])]}},{key:"descriptionTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.description)?_c('span',[_vm._v(" "+_vm._s(props.dataItem.description.length > 100 ? props.dataItem.description.substring(0, 100) + "..." : props.dataItem.description)+" ")]):_vm._e()])]}},{key:"nameFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"nameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Name"},domProps:{"value":props.value},on:{"input":function (event) {
              methods.change({
                operator: 'contains',
                field: props.field,
                value: event.target.value,
                syntheticEvent: event,
              });
            }}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('nameFilterInput', function (event) {
                methods.change({
                  operator: '',
                  field: '',
                  value: '',
                  syntheticEvent: event,
                });
              })}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"eventTypeFilter",fn:function(ref){
              var props = ref.props;
              var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"eventTypeFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Type"},domProps:{"value":props.value},on:{"input":function (event) {
              methods.change({
                operator: 'contains',
                field: props.field,
                value: event.target.value,
                syntheticEvent: event,
              });
            }}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('eventTypeFilterInput', function (event) {
                methods.change({
                  operator: '',
                  field: '',
                  value: '',
                  syntheticEvent: event,
                });
              })}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"descriptionFilter",fn:function(ref){
              var props = ref.props;
              var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"descriptionFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Description"},domProps:{"value":props.value},on:{"input":function (event) {
              methods.change({
                operator: 'contains',
                field: props.field,
                value: event.target.value,
                syntheticEvent: event,
              });
            }}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('descriptionFilterInput', function (event) {
                methods.change({
                  operator: '',
                  field: '',
                  value: '',
                  syntheticEvent: event,
                });
              })}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"optionsTemplate",fn:function(ref){
              var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.editEvent(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}}),_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])],1),_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function () {
                  _vm.indexToDelete = props.dataItem.id;
                  _vm.eventToDelete = props.dataItem;
                  _vm.statusToDelete = props.dataItem.statusId;
                  _vm.openConfirmRemoveModal();
                }}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v("Remove")])],1),(_vm.serviceId)?_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"title":props.dataItem.statusId
                  ? 'Unpublish on Status Page'
                  : 'Publish to Status Page'},on:{"click":function () {
                  _vm.indexToPublish = props.dataItem;
                  _vm.openConfirmPublishModal();
                }}},[_c('span',[_vm._v(_vm._s(props.dataItem.statusId ? "Unpublish" : "Publish"))])]):_vm._e()])])]}}])}),_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":_vm.hideSidebar}},[_c(_vm.currentComponent,{tag:"component",attrs:{"installation-id":_vm.installationId,"event":_vm.currentEvent,"service-id":_vm.serviceId,"has-maintenance-setting":_vm.hasMaintenanceSetting},on:{"reloadEvents":_vm.reloadEventsAndCloseSidebar}})],1)],_c('SweetModal',{ref:"confirmDelete",staticClass:"overflowHidden",attrs:{"icon":"warning","blocking":"","title":"Delete event?"},on:{"close":function($event){_vm.openDeleteModal = false}}},[_c('p',[_vm._v("Are you sure you want to delete this Event?")]),(
        _vm.eventToDelete &&
          _vm.eventToDelete.commonInstallationEvents &&
          _vm.eventToDelete.commonInstallationEvents.length
      )?[_c('div',{staticClass:"form-check form-check-inline mb-3 mr-5",staticStyle:{"width":"100%","justify-content":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deleteOtherEvents),expression:"deleteOtherEvents"}],staticClass:"form-check-input",attrs:{"id":"deleteOtherEvents","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.deleteOtherEvents)?_vm._i(_vm.deleteOtherEvents,null)>-1:(_vm.deleteOtherEvents)},on:{"change":function($event){var $$a=_vm.deleteOtherEvents,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.deleteOtherEvents=$$a.concat([$$v]))}else{$$i>-1&&(_vm.deleteOtherEvents=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.deleteOtherEvents=$$c}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"deleteOtherEvents"}},[_vm._v("Do you want to delete the linked Events?")])]),(_vm.openDeleteModal && _vm.deleteOtherEvents)?_c('InstallationEventSelectList',{attrs:{"events":_vm.eventToDelete.commonInstallationEvents,"selected":_vm.selectedEvents},on:{"setSelectedList":function($event){_vm.selectedEvents = $event}}}):_vm._e()]:_vm._e(),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){_vm.$refs.confirmDelete.close();
        _vm.openDeleteModal = false;}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"times"}}),_c('span',[_vm._v(_vm._s(_vm.$t("cancel")))])],1),_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.removeEvent()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])],1),_c('div',{staticClass:"clearfix"})],2),_c('SweetModal',{ref:"confirmPublish",staticClass:"overflowHidden",attrs:{"icon":"warning","blocking":"","title":_vm.indexToPublish && _vm.indexToPublish.statusId ? 'Unpublish' : 'Publish'}},[(_vm.indexToPublish && _vm.indexToPublish.statusId)?_c('p',[_vm._v(" Are you sure you want to unpublish this Event on https://alturos.freshstatus.io/? ")]):_c('p',[_vm._v(" Are you sure you want to publish this Event to https://alturos.freshstatus.io/? ")]),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.confirmPublish.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"times"}}),_c('span',[_vm._v(_vm._s(_vm.$t("cancel")))])],1),_c('button',{staticClass:"btn btn-success float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.publishEvent()}},slot:"button"},[_c('span',[_vm._v(_vm._s(_vm.indexToPublish && _vm.indexToPublish.statusId ? "Unpublish" : "Publish"))])]),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }